import React, { useState } from "react";
import { Link } from "react-router-dom";
import Accordion from "./Accordion"; // adjust the import path as needed
import "./footer.scss";

const Footer = () => {
  const [openAccordion, setOpenAccordion] = useState(null);

  const toggleAccordion = (index) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };

  return (
    <footer className="footer">
      <section className="footer__navigation">
        <div className="grid--footer__navigation">
          <div className="footer__navigation--list">
            <h5 className="footer__navigation--title">Navigate</h5>
            <ul>
              <li className="footer__navigation--item">
                <Link to="/work">Work </Link>
              </li>
              <li className="footer__navigation--item">
                <Link to="/service">Services </Link>
              </li>
              <li className="footer__navigation--item">
                <Link to="/madarth-about-us">About </Link>
              </li>
              <li className="footer__navigation--item">
                <Link to="https://joinatmadarth.notion.site/" target="_blank">
                  Careers{" "}
                </Link>
              </li>
              <li className="footer__navigation--item">
                <Link to="/contact-us">Contact </Link>
              </li>
            </ul>
          </div>
          <div className="footer__navigation--list">
            <h5 className="footer__navigation--title">Follow</h5>
            <ul>
              <li className="footer__navigation--item">
                <Link to="https://www.instagram.com/madarth.agency">
                  Instagram{" "}
                </Link>
              </li>
              <li className="footer__navigation--item">
                <Link to="https://in.linkedin.com/company/madarth">
                  LinkedIn{" "}
                </Link>
              </li>
              <li className="footer__navigation--item">
                <Link to="https://medium.com/@madarth.com">Medium </Link>
              </li>
              <li className="footer__navigation--item">
                <Link to="https://www.youtube.com/channel/UC2f6CLXgF4eNgxu2EPLvWvg">
                  YouTube{" "}
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer__navigation--list">
            <h5 className="footer__navigation--title">Contact</h5>
            <p className="footer__navigation--item">
              <span> Get started </span>
              <Link to="mailto:pranitha@madarth.com">
                pranitha@madarth.com{" "}
              </Link>
            </p>
          </div>
          <div className="footer__navigation--list">
            <h5 className="footer__navigation--title">Visit</h5>
            <address>
              4, Alamelumangapuram, <br /> Mylapore, Chennai, <br />
              Tamil Nadu 600004
            </address>
          </div>
        </div>
      </section>
      <section className="footer__capabilities">
        <Accordion
          title="Our Capabilities"
          isOpen={openAccordion === 0}
          onClick={() => toggleAccordion(0)}
        >
          <div className="brand-strategy">
            <h2 className="subTitle">Branding : </h2>
            <h3 className="subTitle-Sub">Brand Strategy & Consultation -</h3>
            <ul className="subList">
              <li>Brand Positioning</li>
              <li>Brand Architecture</li>
              <li>Competitor & Market Analysis*</li>
              <li>Brand Purpose, Vision & Mission Development</li>
            </ul>
            <h3 className="subTitle-Sub">Brand Identity Design - </h3>
            <ul className="subList">
              <li>Logo Design & Development</li>
              <li>Brand Naming & Tagline Creation</li>
              <li>Visual Identity Systems</li>
              <li>Typography & Colour Palette Creation</li>
            </ul>
            <h3 className="subTitle-Sub">Brand Guidelines - </h3>
            <ul className="subList">
              <li>Comprehensive Brand Manuals</li>
              <li>Voice & Tone Guidelines</li>
              <li>Visual and Communication Style Guide</li>
              <li>Consistency in Multi-channel Branding</li>
            </ul>
            <h3 className="subTitle-Sub">Rebranding - </h3>
            <ul className="subList">
              <li>Brand Refresh & Evolution</li>
              <li>Market Repositioning</li>
              <li>Legacy Brand Modernisation</li>
              <li>Brand Equity Audit*</li>
            </ul>
            <h3 className="subTitle-Sub">Packaging Design - </h3>
            <ul className="subList">
              <li>Product Packaging Concepts</li>
              <li>Eco-friendly Packaging Solutions</li>
              <li>Label Design</li>
              <li>Structural and Functional Packaging</li>
            </ul>
          </div>
          <div className="communication">
            <h2 className="subTitle">Communication : </h2>
            <h3 className="subTitle-Sub">Advertising Campaigns - </h3>
            <ul className="subList">
              <li>Print, TV, Radio, and Outdoor Ads</li>
              <li>
                Digital Ad Campaigns (Google, Facebook, Instagram, LinkedIn,
                YouTube)
              </li>
              <li>Creative Planning & Copywriting</li>
            </ul>

            <h3 className="subTitle-Sub">Content Strategy & Studio</h3>
            <ul className="subList">
              <li>Content Writing & Editorial Services</li>
              <li>Scriptwriting & Video Production</li>
              <li>Blog & Article Writing</li>
              <li>Social Media Content Strategy & Development</li>
            </ul>
            <h3 className="subTitle-Sub">Digital Media Outreach</h3>
            <ul className="subList">
              <li>Press Release Writing</li>
              <li>Influencer & Celebrity Collaborations</li>
              <li>
                Crisis Communication & Reputation Management (ORM - Online
                Reputation Management)
              </li>
            </ul>
            <h3 className="subTitle-Sub">Digital Marketing</h3>
            <ul className="subList">
              <li>Search Engine Optimization (SEO)</li>
              <li>Search Engine Marketing (SEM)</li>
              <li>Social Media Marketing (SMM)</li>
              <li>Email Marketing Campaigns</li>
            </ul>
            <h3 className="subTitle-Sub">Internal Communication</h3>
            <ul className="subList">
              <li>Corporate Communication Strategy</li>
              <li>Employee Engagement Campaigns</li>
              <li>Internal Newsletters</li>
              <li>Leadership Messaging</li>
            </ul>
          </div>
          <div className="branding">
            <h2 className="subTitle">Branding : </h2>
            <h3 className="subTitle-Sub">Website Design & Development</h3>
            <ul className="subList">
              <li>UX/UI Design</li>
              <li>Custom Website Development</li>
              <li>E-commerce Solutions</li>
              <li>Website Maintenance & Optimisation</li>
            </ul>
            <h3 className="subTitle-Sub">Digital Transformation</h3>
            <ul className="subList">
              <li>Digital Experience Strategy</li>
              <li>Omni-channel Customer Journeys</li>
              <li>Martech Consultation (CRM, Automation Tools)</li>
              <li>Data Analytics & Consumer Insights</li>
            </ul>
            <h3 className="subTitle-Sub">Event Ideation & Design</h3>
            <ul className="subList">
              <li>Corporate Events & Launch Ideas</li>
              <li>Experiential Marketing Campaigns</li>
              <li>Brand Activations Ideas & Execution Support</li>
              <li>Virtual Events & Webinars</li>
            </ul>
            <h3 className="subTitle-Sub">Retail Experience Design</h3>
            <ul className="subList">
              <li>In-store Branding & Visual Merchandising</li>
              <li>Multinational Corporate Office Branding</li>
              <li>Franchise Store Layout Design</li>
              <li>Pop-up Stores & Interactive Installations</li>
              <li>Augmented Reality (AR) & Virtual Reality (VR) Experiences</li>
            </ul>
            <h3 className="subTitle-Sub">Customer Engagement & Loyalty Programs</h3>
            <ul className="subList">
              <li>Customer Relationship Management (CRM) Support</li>
              <li>Loyalty Program Ideation & Development</li>
              <li>Consumer Surveys & Feedback Loops</li>
              <li>Personalised Experience Design</li>
            </ul>
          </div>
        </Accordion>
      </section>
      <section className="footer__information">
        <p>&copy; {new Date().getFullYear()} Madarth | All rights reserved.</p>
        <p>
          Madarth is a wholly-owned subsidiary of Pixel Boy Media Publicities
          Private Limited.
        </p>
        <p>
          <Link
            to="https://www.google.com/partners/agency?id=7855706381"
            target="_blank"
          >
            <img
              src="https://www.gstatic.com/partners/badge/images/2024/PartnerBadgeClickable.svg"
              alt="Google Partner Badge"
            />
          </Link>
        </p>
      </section>
    </footer>
  );
};

export default Footer;
