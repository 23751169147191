import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from 'react-router-dom';
import FooterCommonCTA from '../components/FooterCommonCTA';

import "./work.scss";

import workAnnapoorna from "../images/page-work/madarth-work-annapoorna.png";
import workAnnapoornaVideo from "../images/page-work/madarth-work-annapoorna.mp4";
import workAha from "../images/page-work/madarth-work-aha.png";
import workAhaVideo from "../images/page-work/madarth-home-page-work-aha.mp4";
import workCSK from "../images/page-work/madarth-work-chennai-super-kings.png";
import workCSKVideo from "../images/page-work/madarth-work-chennai-super-kings.mp4";
import workRace from "../images/page-work/madarth-work-race.jpg";
import workAmul from "../images/page-work/madarth-work-amul.png";
import workAmulVideo from "../images/page-work/madarth-work-amul.mp4";
// import workNTTF from "../images/page-work/madarth-work-nttf.png";
import workNTTFGIF from "../images/page-work/madarth-home-page-work-nttf.gif";
import workSundariSilks25 from "../images/page-work/madarth-work-sundarisilks-25-years.png";
import workSundariSilks25Video from "../images/page-work/madarth-home-page-work-sundarisilks.mp4";
import workNova from "../images/page-work/madarth-work-nova.png";
import workNovaVideo from "../images/page-work/madarth-work-nova.mp4";
import workJSK from "../images/page-work/madarth-work-joburg-super-kings.png";
import workJSKVideo from "../images/page-work/madarth-work-joburg-super-kings.mp4";
import workSundariSilksMargazhi from "../images/page-work/madarth-work-sundarisilks-margazhi-utsav.png";
import workSundariSilksMargazhiVideo from "../images/page-work/madarth-work-sundarisilks-margazhi-utsav.mp4";
// import workDahNAY from "../images/page-work/madarth-work-dahnay.png";
// import workDahNAYVideo from "../images/page-work/madarth-home-page-work-dahnay.mp4";

function Work() {
  return (
    <div>
      <Helmet>
        <title>Portfolio | MADARTH® | Branding, Advertising, Design &amp; Digital Marketing Agency India</title>
        <meta
          name="description"
          content="Madarth is a branding, advertising, design and digital marketing agency. We create compelling and relevant solutions for our clients, across verticals."
        />
      </Helmet>
      <div className="page--Work">
        <section className="grid__box pageHero ">
          <h1 className="pageHero__title">
            Unified approach <br /> with creative <br /> synergy
          </h1>
          <p className="pageHero__content">
            We understand your cultural roots and turn them into powerful stories.
            We celebrate big ideas and make them work across channels. Our secret
            sauce? Let’s talk over a steaming cup of filter coffee. 😉
          </p>
        </section>
        <section className="grid__box work--lists">
          <Link to="/work/annpoorna-masala" className="work--list">
            <div className="work--list__image">
              <video
                width="100%"
                height="100%"
                preload="auto"
                poster={workAnnapoorna}
                autoPlay
                muted
                loop
                playsInline
                aria-label="Annapoorna"
                className="slideContentObject"
                style={{
                  objectFit: "cover",
                  display: "block",
                  width: "100%",
                  height: "100%",
                }}
              >
                <source src={workAnnapoornaVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="work--list__content">
              <h3 className="work--list__content--title"> Annapoorna </h3>
              <h3 className="work--list__content--tag"> Campaign That Unites </h3>
            </div>
          </Link>
          <Link to="/work/aha" className="work--list">
            <div className="work--list__image">
              <video
                width="100%"
                height="100%"
                preload="auto"
                poster={workAha}
                autoPlay
                muted
                loop
                playsInline
                aria-label="Aha"
                className="slideContentObject"
                style={{
                  objectFit: "cover",
                  display: "block",
                  width: "100%",
                  height: "100%",
                }}
              >
                <source src={workAhaVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="work--list__content">
              <h3 className="work--list__content--title"> AHA </h3>
              <h3 className="work--list__content--tag"> 100% Tamil Nadu, 200% Entertainment </h3>
            </div>
          </Link>
          <Link to="/work/chennai-super-kings" className="work--list">
            <div className="work--list__image">
              <video
                width="100%"
                height="100%"
                preload="auto"
                poster={workCSK}
                autoPlay
                muted
                loop
                playsInline
                aria-label="Chennai Super Kings"
                className="slideContentObject"
                style={{
                  objectFit: "cover",
                  display: "block",
                  width: "100%",
                  height: "100%",
                }}
              >
                <source src={workCSKVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="work--list__content">
              <h3 className="work--list__content--title"> Chennai Super Kings </h3>
              <h3 className="work--list__content--tag"> Whistle Podu, Yet Again! </h3>
            </div>
          </Link>
          <Link to="/work/race-ayirathil-oru-kathai" className="work--list">
            <div className="work--list__image">
              <img src={workRace} alt="Race" />
            </div>
            <div className="work--list__content">
              <h3 className="work--list__content--title"> Race </h3>
              <h3 className="work--list__content--tag"> One in a Thousand Campaign </h3>
            </div>
          </Link>
          <Link to="/work/amul" className="work--list">
            <div className="work--list__image">
              <video
                width="100%"
                height="100%"
                preload="auto"
                poster={workAmul}
                autoPlay
                muted
                loop
                playsInline
                aria-label="Amul"
                className="slideContentObject"
                style={{
                  objectFit: "cover",
                  display: "block",
                  width: "100%",
                  height: "100%",
                }}
              >
                <source src={workAmulVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="work--list__content">
              <h3 className="work--list__content--title"> Amul </h3>
              <h3 className="work--list__content--tag"> Amul's Tamil Nadu Market Entry: Becoming Namoor Karan </h3>
            </div>
          </Link>
          <Link to="/work/nttf" className="work--list">
            <div className="work--list__image">
              <img src={workNTTFGIF} alt="NTTF" />
            </div>
            <div className="work--list__content">
              <h3 className="work--list__content--title"> NTTF </h3>
              <h3 className="work--list__content--tag"> The Launch of NTTF </h3>
            </div>
          </Link>
          <Link to="/work/sundari-silks-25-years" className="work--list">
            <div className="work--list__image">
                    <video
                            width="100%"
                            height="100%"
                            preload="auto"
                            poster={workSundariSilks25}
                            autoPlay
                            muted
                            loop
                            playsInline
                            aria-label="Sundari Silks"
                            className="slideContentObject"
                            style={{
                                objectFit: "cover",
                                display: "block",
                                width: "100%",
                                height: "100%",
                              }}
                        >
                            <source src={workSundariSilks25Video} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
            </div>
            <div className="work--list__content">
              <h3 className="work--list__content--title"> Sundari Silks </h3>
              <h3 className="work--list__content--tag"> The Sundari Silks Legacy: Draping Dreams for 25 Years </h3>
            </div>
          </Link>
          <Link to="/work/nova" className="work--list">
            <div className="work--list__image">
              <video
                width="100%"
                height="100%"
                preload="auto"
                poster={workNova}
                autoPlay
                muted
                loop
                playsInline
                aria-label="Nova"
                className="slideContentObject"
                style={{
                  objectFit: "cover",
                  display: "block",
                  width: "100%",
                  height: "100%",
                }}
              >
                <source src={workNovaVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="work--list__content">
              <h3 className="work--list__content--title"> NOVA </h3>
              <h3 className="work--list__content--tag"> Campaign for a Real Estate Brand That Was a Plus Hit </h3>
            </div>
          </Link>
          <Link to="/work/joburg-super-kings" className="work--list">
            <div className="work--list__image">
              <video
                width="100%"
                height="100%"
                preload="auto"
                poster={workJSK}
                autoPlay
                muted
                loop
                playsInline
                aria-label="Joburg Super Kings"
                className="slideContentObject"
                style={{
                  objectFit: "cover",
                  display: "block",
                  width: "100%",
                  height: "100%",
                }}
              >
                <source src={workJSKVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="work--list__content">
              <h3 className="work--list__content--title"> Joburg Super Kings </h3>
              <h3 className="work--list__content--tag"> From the Kings' Universe: Joburg Super Kings </h3>
            </div>
          </Link>
          <Link to="/work/sundari-silks-margazhi-utsavam" className="work--list">
            <div className="work--list__image">
              <video
                width="100%"
                height="100%"
                preload="auto"
                poster={workSundariSilksMargazhi}
                autoPlay
                muted
                loop
                playsInline
                aria-label="Sundari Silks Margazhi Utsav"
                className="slideContentObject"
                style={{
                  objectFit: "cover",
                  display: "block",
                  width: "100%",
                  height: "100%",
                }}
              >
                <source src={workSundariSilksMargazhiVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="work--list__content">
              <h3 className="work--list__content--title"> Sundari Silks </h3>
              <h3 className="work--list__content--tag"> Strings, Songs & Silks: A Margazhi Utsav to Remember </h3>
            </div>
          </Link>
          {/* <Link to="/work/dahnay" className="work--list">
          <div className="work--list__image">
                        <video
                            width="100%"
                            height="100%"
                            preload="auto"
                            poster={workDahNAY}
                            autoPlay
                            muted
                            loop
                            playsInline
                            aria-label="DahNAY"
                            className="slideContentObject"
                            style={{
                                objectFit: "cover",
                                display: "block",
                                width: "100%",
                                height: "100%",
                              }}
                        >
                            <source src={workDahNAYVideo} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
          </div>
          <div className="work--list__content">
            <h3 className="work--list__content--title"> DahNAY </h3>
            <h3 className="work--list__content--tag"> DahNAY's Grow The Now Journey </h3>
          </div>
        </Link> */}
        </section>
        <FooterCommonCTA
          content="Tell us your story. We’d love to hear from you."
          linkText="Let’s talk"
          linkUrl="/contact-us"
        />
      </div>
    </div>
  );
}

export default Work;
