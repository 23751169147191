import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from "react-router-dom";

import "./servicesubAnimation.scss";

// Register the ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

const ServicesubAnimation = () => {
  const containerRef = useRef(null);
  const panelRefs = useRef([]);
  useEffect(() => {
    // Get the current viewport width
    const viewportWidth = window.innerWidth;

    // Disable animations if the viewport width is less than 992px
    if (viewportWidth < 992) {
      return;
    }

    // Save initial styles for responsiveness
    ScrollTrigger.saveStyles(panelRefs.current);

    const panels = panelRefs.current;
    let scrollTween;

    // Match media for responsive animations
    ScrollTrigger.matchMedia({
      "(min-width: 992px)": function () {
        // Set up the horizontal scrolling animation
        scrollTween = gsap.to(panels, {
          xPercent: -100 * (panels.length - 1),
          ease: "none",
          scrollTrigger: {
            trigger: containerRef.current,
            pin: true,
            scrub: 0.1,
            end: "+=3000",
          },
        });

        // Animate p.panelAbout under the .blue panel
        // gsap.fromTo(
        //   ".blue .panelAbout",
        //   { scale: 0.2, autoAlpha: 0.2 },
        //   {
        //     scale: 1,
        //     autoAlpha: 1,
        //     scrollTrigger: {
        //       trigger: ".blue",
        //       scrub: 0.1,
        //       start: "top center",
        //       endTrigger: panels[1],
        //     },
        //   }
        // );

        // Set up individual ScrollTriggers
        panels.forEach((panel, i) => {
          ScrollTrigger.create({
            trigger: panel,
            containerAnimation: scrollTween,
            start: i === panels.length - 1 ? "right right" : "right 50%",
            end: "left 50%",
            onToggle: (self) =>
              gsap.to(".marker-" + (i + 1), {
                duration: 0.25,
                autoAlpha: self.isActive ? 1 : 0,
              }),
          });
        });
      },

      "(max-width: 992px)": function () {
        // Clear animations for smaller screens
        if (scrollTween) {
          scrollTween.kill();
        }
        ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
      },
    });

    // Clean up on component unmount
    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
      if (scrollTween) {
        scrollTween.kill();
      }
    };
  }, []);

  return (
    <div>
      <div ref={containerRef} className="container">
                <div className="panel blue" ref={el => panelRefs.current[0] = el}>
                 <p className="panelAbout">And we partner with the visionaries, the bold,  and
                 the ones with something to say</p>
                </div>
                <section className="panel red" ref={el => panelRefs.current[1] = el}>
                <div className="image">
                  {/* <img src={serviceFuture} alt="Generational transformation for family businesses" /> */}
                </div>
                <div className="content">
                  <div className="content__title">Generational transformation for family businesses</div>
                  <Link to="/service">
                  <span>To Know more </span>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1.5 11.2493C1.5 11.0725 1.57023 10.9029 1.69523 10.7779C1.82024 10.6529 1.98979 10.5827 2.16657 10.5827H17.8884L13.693 6.38861C13.5678 6.26344 13.4975 6.09368 13.4975 5.91668C13.4975 5.73967 13.5678 5.56991 13.693 5.44474C13.8181 5.31958 13.9879 5.24926 14.1649 5.24926C14.3419 5.24926 14.5117 5.31958 14.6368 5.44474L19.9694 10.7773C20.0315 10.8392 20.0807 10.9128 20.1143 10.9938C20.1479 11.0748 20.1652 11.1616 20.1652 11.2493C20.1652 11.3369 20.1479 11.4238 20.1143 11.5047C20.0807 11.5857 20.0315 11.6593 19.9694 11.7212L14.6368 17.0538C14.5117 17.1789 14.3419 17.2493 14.1649 17.2493C13.9879 17.2493 13.8181 17.1789 13.693 17.0538C13.5678 16.9286 13.4975 16.7589 13.4975 16.5818C13.4975 16.4048 13.5678 16.2351 13.693 16.1099L17.8884 11.9158H2.16657C1.98979 11.9158 1.82024 11.8456 1.69523 11.7206C1.57023 11.5956 1.5 11.426 1.5 11.2493Z" fill="black"/></svg>
                  </Link>
                </div>
                </section>
                <section className="panel gray" ref={el => panelRefs.current[2] = el}>
                <div className="image">
                  {/* <img src={serviceGrowth} alt="Brand scale up for Indian corporates" /> */}
                </div>
                <div className="content">
                  <div className="content__title">Brand scale up for Indian corporates</div>
                  <Link to="/service">
                  <span>To Know more </span>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1.5 11.2493C1.5 11.0725 1.57023 10.9029 1.69523 10.7779C1.82024 10.6529 1.98979 10.5827 2.16657 10.5827H17.8884L13.693 6.38861C13.5678 6.26344 13.4975 6.09368 13.4975 5.91668C13.4975 5.73967 13.5678 5.56991 13.693 5.44474C13.8181 5.31958 13.9879 5.24926 14.1649 5.24926C14.3419 5.24926 14.5117 5.31958 14.6368 5.44474L19.9694 10.7773C20.0315 10.8392 20.0807 10.9128 20.1143 10.9938C20.1479 11.0748 20.1652 11.1616 20.1652 11.2493C20.1652 11.3369 20.1479 11.4238 20.1143 11.5047C20.0807 11.5857 20.0315 11.6593 19.9694 11.7212L14.6368 17.0538C14.5117 17.1789 14.3419 17.2493 14.1649 17.2493C13.9879 17.2493 13.8181 17.1789 13.693 17.0538C13.5678 16.9286 13.4975 16.7589 13.4975 16.5818C13.4975 16.4048 13.5678 16.2351 13.693 16.1099L17.8884 11.9158H2.16657C1.98979 11.9158 1.82024 11.8456 1.69523 11.7206C1.57023 11.5956 1.5 11.426 1.5 11.2493Z" fill="black"/></svg>
                  </Link>
                </div>
                </section>
                <section className="panel purple" ref={el => panelRefs.current[3] = el}>
                <div className="image">
                  {/* <img src={serviceInformation} alt="Indian market entry for global brands" /> */}
                </div>
                <div className="content">
                  <div className="content__title">Indian market entry for global brands</div>
                  <Link to="/service">
                  <span>To Know more </span>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1.5 11.2493C1.5 11.0725 1.57023 10.9029 1.69523 10.7779C1.82024 10.6529 1.98979 10.5827 2.16657 10.5827H17.8884L13.693 6.38861C13.5678 6.26344 13.4975 6.09368 13.4975 5.91668C13.4975 5.73967 13.5678 5.56991 13.693 5.44474C13.8181 5.31958 13.9879 5.24926 14.1649 5.24926C14.3419 5.24926 14.5117 5.31958 14.6368 5.44474L19.9694 10.7773C20.0315 10.8392 20.0807 10.9128 20.1143 10.9938C20.1479 11.0748 20.1652 11.1616 20.1652 11.2493C20.1652 11.3369 20.1479 11.4238 20.1143 11.5047C20.0807 11.5857 20.0315 11.6593 19.9694 11.7212L14.6368 17.0538C14.5117 17.1789 14.3419 17.2493 14.1649 17.2493C13.9879 17.2493 13.8181 17.1789 13.693 17.0538C13.5678 16.9286 13.4975 16.7589 13.4975 16.5818C13.4975 16.4048 13.5678 16.2351 13.693 16.1099L17.8884 11.9158H2.16657C1.98979 11.9158 1.82024 11.8456 1.69523 11.7206C1.57023 11.5956 1.5 11.426 1.5 11.2493Z" fill="black"/></svg>
                  </Link>
                </div>
                </section>
            </div>
    </div>
  );
};

export default ServicesubAnimation;
