import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from 'react-router-dom';

import aboutHero from "../images/madarth-about-us-hero.png";

import "./about.scss";

function About() {
  return (
    <div>
      <Helmet>
        <title>
          About | MADARTH® | Building brands with authentic identities, across
          geographies
        </title>
        <meta
          name="description"
          content="Madarth is a branding, advertising, design and digital marketing agency. We create compelling and relevant solutions for our clients, across verticals."
        />
      </Helmet>
      <div className="page--aboutus">
        <section className="pageHero">
          <div className="title">
            <h1>This is us</h1>
          </div>
          <div className="content">
            <p>
              Madarth®’s journey began in Chennai in 2005, and we’ve remained
              deeply rooted in this city’s vibrant entrepreneurial culture. Over
              the years, we’ve evolved from a design house into a branding
              powerhouse, learning from the best in the country while seamlessly
              adapting to the digital age. Yet, we’ve never lost the
              storytelling craft we mastered during the traditional advertising
              era.
            </p>
            <p className="heroContent">
              Our mission is clear: to blend heritage with innovation, guiding
              Indian brands to become visionary forces of the future.
            </p>
            <p>
              What sets us apart? At Madarth®, we’re not just an agency. We’re
              your partners in transformation, deeply invested in your growth.
              Our approach is honest, un-agency-like, and focused on results. We
              pair creativity with grit and deliver strategic insights that make
              your brand shine, driving tangible impact. That’s the Madarth®
              way.
            </p>
          </div>
        </section>
        <section className="page--aboutus__banner">
          <img src={aboutHero} alt="" />
        </section>
        <section className="page--aboutus__ethos">
          <h2 className="title">Our ethos</h2>
          <ul className="our__ethos--lists">
            <li>
              <strong>Beyond Business</strong>
              <span>
                At Madarth®, profit isn't the driver. We're all about
                deep-rooted relationships and lasting impact
              </span>
            </li>
            <li>
              <strong>Holistic Value</strong>
              <span>
                We're not just about stakeholders. Employees, partners,
                community- everyone's part of the Madarth® family.
              </span>
            </li>
            <li>
              <strong>Symbiotic Synergy</strong>
              <span>
                Brand and business? Two sides of the same coin. At Madarth®,
                it’s all about harmony.
              </span>
            </li>
            <li>
              <strong>Human-first Approach</strong>
              <span>
                For Madarth®, caring isn't just a job. It's our calling; a
                passion and our very essence.
              </span>
            </li>
          </ul>
        </section>
        <section className="grid__fluid common--cta common--ctawith">
          <div className="grid__box">
            <div>
            <h2 className="common--ctaTitle">What’s your brand’s story?</h2>
            <p>Let’s uncover it, believe in it and transform it together.</p>
            </div>
            <Link to="/contact-us">Join us</Link>
          </div>
        </section>
      </div>
    </div>
  );
}

export default About;
