import React from "react";
import { Helmet } from "react-helmet-async";

import FooterCommonCTA from '../../components/FooterCommonCTA';

import racePageCover from "../../images/work/race/image/race-cover.jpg";
import race1 from "../../images/work/race/image/race-1.png";
import race2 from "../../images/work/race/image/race-2.png";
// import race3 from "../../images/work/race/image/race-3.png";
// import race4 from "../../images/work/race/image/race-4.png";

import raceVideoCover1 from "../../images/work/race/video-cover/race-1.png";
import raceVideoCover2 from "../../images/work/race/video-cover/race-2.png";


import raceVideo1 from "../../images/work/race/video/race-1.mp4";
import raceVideo2 from "../../images/work/race/video/race-2.mp4";


function RaceAyirathilOruKathai() {
  return (
    <div>
      <Helmet>
        <title>
            Race Ayirathil Oru Kathai
        </title>
        <meta
          name="description"
          content="Madarth is a branding, advertising, design and digital marketing agency. We create compelling and relevant solutions for our clients, across verticals."
        />
      </Helmet>

      <section className="project__detail">
            <div className="grid__box">
            <div className="section__title--content">
                        <div className="text">
                            <h1 className="project__detail--title">Race</h1>
                            <p className="project__detail--description">A one in a thousand campaign for a coaching institute</p>
                            <p className="project__detail--tag">
                                <span>Omni channel Strategy</span> |
                                <span>Branding</span>
                            </p>
                        </div>
                    </div>
                <div className="section__single">
                    <img loading="lazy" src={racePageCover} alt="" />
                </div>
                {/* <div className="section__title--content">
                    <div className="title">
                        <h2 className="project__detail--year">2021</h2>
                    </div>
                    <div className="text">
                        <h1 className="project__detail--title">Race</h1>
                        <p className="project__detail--description">A one in a thousand campaign for a coaching institute
                        </p>
                    </div>
                </div> */}
                <div className="section__content">
                    <div className="title">
                        <p className="project__detail--type">Social Media Strategy</p>
                    </div>
                    <div className="text">
                        <p>RACE Institute's mission is to give wings to the dreams of millions. Their approach is designed to turn those dreams of thousands of aspirants’ into stories of eligibility and thus reality.</p>
                        <p>As one of India's leading coaching institutes for bank and government examinations, the institute worked on motivating and empowering youth in rural and semi-rural regions with the right educational resources and employment opportunities.</p>
                    </div>
                </div>
                <div className="section__single padding:bottom:0px">
                    <img loading="lazy" src={race1} alt="" />
                </div>
                <div className="section__single">
                    <img loading="lazy" src={race2} alt="" />
                </div>
                <div className="section__content">
                    <div className="text">
                        <p>Thanks to a unique 7-step methodology, more students from RACE Institute landed jobs than those from other institutes. This inspiring success story needed to be told in a manner that inspired more. And thus was born the campaign "Aayirathil Oru Kadhai" (One story from a thousand), an omni-channel campaign that showcased heartwarming success stories of students from RACE Institute who overcame adversity and conquered their dreams.</p>
                        <p>The campaign showcased original success stories that were each one from a thousand. It helped build empathy and relatability among the target audience. It wasn’t merely a promise, but proof of the promise being delivered.</p>
                        <p>Original success stories were showcased on print and outdoor media, featuring actual people who conquered their dreams with RACE Institute.</p>
                    </div>
                </div>
                <div className="section__single">
                    <video src={raceVideo1} poster={raceVideoCover1} autoPlay loop
                    muted preload="auto" width="640" height="360" playsInline className="width:100 height:auto">
                    Your browser does not support the video tag.
                    </video>
                </div>
                <div className="section__content">
                    <div className="text">
                        <p>The TVC portrayed a young woman with an infant attending an interview. As part of the strategy, the casting was consciously skewed towards a pan-India look. This helped create a sense of largeness and credibility. On the other end, it has also overcome challenges that came with using actual people.</p>
                    </div>
                </div>
                <div className="section__single">
                    <video src={raceVideo2} poster={raceVideoCover2} autoPlay loop
                    muted preload="auto" width="640" height="360" playsInline className="width:100 height:auto">
                    Your browser does not support the video tag.
                    </video>
                </div>
                <div className="section__content">
                    <div className="text">
                        <p>The TVC released on leading channels made considerable impact. Press and outdoor featuring actual students paid rich dividends. The campaign was a runaway success, resulting in an unprecedented spike in enrollments.</p>
                        <p>This success story also paved the way for RACE Institute’s acquisition by the Kalpathi Group, one of the leading pioneers in the EduTech space.</p>
                        <p>This resulted in the birth of 'Veranda Race'. After all, Good stories make Great Brands</p>
                    </div>
                </div>
            </div>
        </section>
      <FooterCommonCTA
          content="Tell us your story. We’d love to hear from you."
          linkText="Let’s talk"
          linkUrl="/contact-us"
        />
    </div>
  );
}

export default RaceAyirathilOruKathai;
