import React from "react";
import { Helmet } from "react-helmet-async";

import FooterCommonCTA from "../components/FooterCommonCTA";
import SlidingGallery from "./SlidingGallery";
import ServicesubAnimation from "./ServicesubAnimation";

import headerBarMobile from "../images/page-home/home-page-intro-bg-mobile.png";

import branding from "../images/page-home/service/madarth-home-page-branding.mp4";
import communication from "../images/page-home/service/madarth-home-page-communication.mp4";
import experience from "../images/page-home/service/madarth-home-page-experience.mp4";


import "./home.scss";

function Home() {
  return (
    <div>
      <Helmet>
        <title>
          MADARTH® | Branding, Advertising, Design & Digital Marketing Agency
          India
        </title>
        <meta
          name="description"
          content="Madarth is a branding, advertising, design, and digital marketing agency. We create compelling and relevant solutions for our clients, across verticals."
        />
      </Helmet>
      <div className="page--home">
        <section className="grid__box pageHero ">
          <h1 className="pageHero__title">
            We help Indian <br /> businesses become
            <br />
            <span className="highlight">
              world-class brands.
              <span className="highlight-background">
                <img src={headerBarMobile} alt="" />
                {/* <img src={headerBar} alt="" /> */}
              </span>
            </span>
          </h1>
          <p className="pageHero__content">
            With a deep understanding of India’s diverse culture, we believe
            every Indian business has a timeless story. Let us make yours
            resonate in today’s world, with impact.
          </p>
        </section>
        <section className="grid__box homePage--work">
          <SlidingGallery />
        </section>
        <section className="grid__box homePage--services">
          <h2 className="homePage--services__title">
            We don’t just build brands - we nurture them with
          </h2>
          <ul>
            <li className="homePage--services__list">
              <div className="homePage--services__list__content">
                <h3 className="homePage--services__list__title">Branding</h3>
                <p>
                  Crafting timeless identities that resonate with audiences.
                </p>
              </div>
              <div className="homePage--services__list__image gif-container-1">
              <video
                width="100%"
                height="100%"
                preload="auto"
                autoPlay
                muted
                loop
                playsInline
                aria-label="Aha"
                className="slideContentObject"
                style={{
                  objectFit: "contain",
                  display: "block",
                  width: "100%",
                  height: "100%",
                }}
              >
                <source src={branding} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              </div>
            </li>
            <li className="homePage--services__list">
              <div className="homePage--services__list__content">
                <h3 className="homePage--services__list__title">
                  Communication
                </h3>
                <p>
                  Delivering clear, compelling messages that inspire action.
                </p>
              </div>
              <div className="homePage--services__list__image gif-container-2">
              <video
                width="100%"
                height="100%"
                preload="auto"
                autoPlay
                muted
                loop
                playsInline
                aria-label="Aha"
                className="slideContentObject"
                style={{
                  objectFit: "contain",
                  display: "block",
                  width: "100%",
                  height: "100%",
                }}
              >
                <source src={communication} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              </div>
            </li>
            <li className="homePage--services__list">
              <div className="homePage--services__list__content">
                <h3 className="homePage--services__list__title">Experience </h3>
                <p>Designing immersive journeys that engage and delight.</p>
              </div>
              <div className="homePage--services__list__image gif-container-3">
              <video
                width="100%"
                height="100%"
                preload="auto"
                autoPlay
                muted
                loop
                playsInline
                aria-label="Aha"
                className="slideContentObject"
                style={{
                  objectFit: "contain",
                  display: "block",
                  width: "100%",
                  height: "100%",
                }}
              >
                <source src={experience} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              </div>
            </li>
          </ul>
        </section>
        <section className="grid__boxs homePage--servicesSub">
          <ServicesubAnimation />
        </section>
        <FooterCommonCTA
          content="If you have a business, we’ll help turn it into a brand. And a world-class one at that."
          linkText="Let’s connect!"
          linkUrl="/contact-us"
        />
      </div>
    </div>
  );
}

export default Home;
