import React from "react";
import { Helmet } from "react-helmet-async";

function Career() {
  return (
    <div>
      <Helmet>
        <title>Career</title>
        <meta name="description" content="career" />
      </Helmet>
     <p className="" style={{textAlign: 'center', fontSize: '2rem', marginTop: '2rem', marginBottom: '2rem'}}> We are hiring via our <a href="https://joinatmadarth.notion.site/">Notion Page</a>.</p>
    </div>
  );
}

export default Career;
