import React from "react";
import { Helmet } from "react-helmet-async";

import FooterCommonCTA from '../../components/FooterCommonCTA';

import sundarisilksCover from "../../images/work/sundarisilks-25/image/sundari-silks-cover.png";
// import sundarisilks1 from "../../images/work/sundarisilks-25/image/sundari-silks-1.png";
// import sundarisilks2 from "../../images/work/sundarisilks-25/image/sundari-silks-2.png";
// import sundarisilks3 from "../../images/work/sundarisilks-25/image/sundari-silks-3.png";
import sundarisilks4 from "../../images/work/sundarisilks-25/image/sundari-silks-4.png";
// import sundarisilks5 from "../../images/work/sundarisilks-25/image/sundari-silks-5.png";
import sundarisilks6 from "../../images/work/sundarisilks-25/image/sundari-silks-6.png";
// import sundarisilks7 from "../../images/work/sundarisilks-25/image/sundari-silks-7.png";
import sundarisilks8 from "../../images/work/sundarisilks-25/image/sundari-silks-8.png";
import sundarisilks9 from "../../images/work/sundarisilks-25/image/sundari-silks-9.png";
import sundarisilks10 from "../../images/work/sundarisilks-25/image/sundari-silks-10.png";
import sundarisilks11 from "../../images/work/sundarisilks-25/image/sundari-silks-11.png";

import sundarisilksVideoCover1 from "../../images/work/sundarisilks-25/video-cover/sundarisilks-1.png";
import sundarisilksVideoCover2 from "../../images/work/sundarisilks-25/video-cover/sundarisilks-2.png";
import sundarisilksVideoCover3 from "../../images/work/sundarisilks-25/video-cover/sundarisilks-3.png";
import sundarisilksVideoCover4 from "../../images/work/sundarisilks-25/video-cover/sundarisilks-4.png";
import sundarisilksVideoCover5 from "../../images/work/sundarisilks-25/video-cover/sundarisilks-5.png";

import sundarisilksVideo1 from "../../images/work/sundarisilks-25/video/sundarisilks-1.mp4";
import sundarisilksVideo2 from "../../images/work/sundarisilks-25/video/sundarisilks-2.mp4";
import sundarisilksVideo3 from "../../images/work/sundarisilks-25/video/sundarisilks-3.mp4";
import sundarisilksVideo4 from "../../images/work/sundarisilks-25/video/sundarisilks-4.mp4";
import sundarisilksVideo5 from "../../images/work/sundarisilks-25/video/sundarisilks-5.mp4";


function SundariSilksWebsite() {
    return (
        <div>
            <Helmet>
                <title>
                    Sundari Silks 
                </title>
                <meta
                    name="description"
                    content="Madarth is a branding, advertising, design and digital marketing agency. We create compelling and relevant solutions for our clients, across verticals."
                />
            </Helmet>
            <section className="project__detail">
                <div className="grid__box">
                    <div className="section__title--content">
                        <div className="text">
                            <h1 className="project__detail--title">Sundari Silks</h1>
                            <p className="project__detail--description">Celebrating 25 years of Sundari Silks: A legacy of
                                tradition</p>
                            <p className="project__detail--tag">
                                <span>Branding</span> |
                                <span>UI/UX</span> |
                                <span>Website</span>
                            </p>
                        </div>
                    </div>
                    <div className="section__single">
                        <img src={sundarisilksCover} alt="" />
                    </div>
                    {/* <div className="section__title--content">
                    <div className="title">
                        <h2 className="project__detail--year">2023</h2>
                    </div>
                    <div className="text">
                        <h1 className="project__detail--title">Sundari Silks</h1>
                        <p className="project__detail--description">Celebrating 25 years of Sundari Silks: A legacy of
                            tradition</p>
                    </div>
                </div> */}
                    <div className="section__content">
                        <div className="title">
                            <p className="project__detail--type">A golden era of milestones</p>
                        </div>
                        <div className="text">
                            <p>Sundari Silks began its golden era in 1949 with the exporting of silk shirts. The first
                                physical store was opened in T.Nagar in 1998, which became a landmark for silk sarees. Over
                                the years, the brand continued to innovate and expand, introducing new collections like
                                Naran, Alankara, and Athirshtam.</p>
                            <p>In 2023, Sundari Silks further expanded its presence by opening a store in Mumbai,
                                reinforcing its commitment to bringing traditional elegance to more customers. Known for its
                                tradition, elegance, and quality, Sundari Silks proudly stepped into its 25th year, making
                                an inevitable mark. To celebrate this milestone, Madarth launched a special anniversary
                                campaign that honoured the brand's rich history while looking ahead to a bright future,
                                blending cherished traditions with modern innovations.</p>
                        </div>
                    </div>
                    <div className="section__single">
                        <video src={sundarisilksVideo2} poster={sundarisilksVideoCover2} autoPlay loop
                            muted preload="auto" width="640" height="360" playsInline className="width:100 height:auto">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="section__content">
                        <div className="title">
                            <p className="project__detail--type">Anniversary Campaign</p>
                        </div>
                        <div className="text">
                            <p><strong>A legacy created by you, us, and threads of our heritage</strong></p>
                            <p>The tagline for the 25th anniversary, "Decades of traditions and innovations. A legacy
                                created by you, us, and threads of our heritage," captured the essence of the campaign. This
                                theme was central to the events and initiatives designed to celebrate the brand’s journey
                                and engage its community.</p>
                            <p>Prominent women of stature have long been associated with Sundari Silks, reflecting the
                                brand's appeal to those who appreciate tradition and elegance. Additionally, the visit of
                                the Denmark Princess highlighted the brand's international reach and the loyalty of its
                                global customer base. This event underscored Sundari Silks' commitment to excellence and its
                                enduring legacy in the world of traditional clothing.</p>
                        </div>
                    </div>

                    <div className="section__content">
                        <div className="title">
                            <p className="project__detail--type">Comprehensive communication strategy</p>
                        </div>
                        <div className="text">
                            <p>The campaign spanned across in-store, website, and social media platforms, ensuring a
                                cohesive and engaging experience.</p>
                        </div>
                    </div>
                    <div className="section__single">
                        <video src={sundarisilksVideo1} poster={sundarisilksVideoCover1} autoPlay loop
                            muted preload="auto" width="640" height="360" playsInline className="width:100 height:auto">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="section__content">
                        <div className="title">
                            <p className="project__detail--type">Social Media</p>
                        </div>
                        <div className="text">
                            <p>A 25-day storytelling initiative was launched, beginning 25 days before the anniversary and
                                concluding with a celebratory post on September 9th. This campaign was dedicated to patrons
                                who have been part of the Sundari Silks family for years.</p>
                        </div>
                    </div>

                    <div className="section__single">
                        <video src={sundarisilksVideo3} poster={sundarisilksVideoCover3} autoPlay loop
                            muted preload="auto" width="640" height="360" playsInline className="width:100 height:auto">
                            Your browser does not support the video tag.
                        </video>
                    </div>

                    <div className="section__single">
                        <img loading="lazy" src={sundarisilks4} alt="" />
                    </div>
                    <div className="section__content">
                        <div className="title">
                            <p className="project__detail--type">Website</p>
                        </div>
                        <div className="text">
                            <p>The campaign spanned across in-store, website, and social media platforms, ensuring a cohesive and engaging experience. A dedicated landing page was created featuring a timeline of Sundari Silks' journey, with a thread of memories. Additionally, the webpage included a tribute to the patrons through blogs, celebrating their stories and contributions to the brand's success. This comprehensive approach not only highlighted the brand's rich heritage but also fostered a sense of community among its customers.</p>
                        </div>
                    </div>
                    <div className="section__single">
                        <video src={sundarisilksVideo5} poster={sundarisilksVideoCover5} autoPlay loop
                            muted preload="auto" width="640" height="360" playsInline className="width:100 height:auto">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={sundarisilks6} alt="" />
                    </div>
                    <div className="section__content">
                        <div className="title"><p className="project__detail--type">Celebrating 25 Years of Sundari Silks</p></div>
                        <div className="text">
                            <p>To mark the 25th anniversary, Sundari Silks integrated the milestone into various packaging elements, including gift wrappers, digital photo frames, thank you cards, and acrylic photo frames, all branded with the 25th anniversary logo to add a special touch to each purchase.</p>
                            <p>The showroom decor featured spinning wheels, spindles, threads, punch cards, and shuttles, creating a direct connection to the looms and highlighting the intricate processes involved in crafting their exquisite sarees, thereby reinforcing the brand's deep-rooted heritage. Additionally, a patrons' timeline was displayed in the store using drop-down cubes in the mutram, showcasing pictures of many patrons alongside the timeline of Sundari Silks. This initiative fostered a sense of community and emphasised the collective effort in the brand's success.</p>
                        </div>
                    </div>
                    <div className="section__single">
                        <video src={sundarisilksVideo4} poster={sundarisilksVideoCover4} autoPlay loop
                            muted preload="auto" width="640" height="360" playsInline className="width:100 height:auto">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="section__content">
                        <div className="title"><p className="project__detail--type">Showcasing the legacy</p></div>
                        <div className="text">
                            <p>The anniversary celebrations aimed to showcase the legacy of Sundari Silks, highlighting its achievements and journey over the years. There was a timeline that emphasised the introduction of authentic techniques like weaving, along with the people and processes involved in the brand’s success.</p>
                        </div>
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={sundarisilks8} alt="" />
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={sundarisilks9} alt="" />
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={sundarisilks10} alt="" />
                    </div>
                    <div className="section__content">
                        <div className="title"><p className="project__detail--type">In-store</p></div>
                        <div className="text">
                            <p>The store featured a patrons' timeline with drop-down cubes in the mutram and a dedicated section celebrating the brand’s history. This in-store engagement created a tangible connection to the brand's legacy.</p>
                            <p>There were several engaging events and activities in the showroom to encourage the involvement and participation of the audience. A block-printing workshop led by the renowned artist Mr. Kalasu Dakshinamurthy was organised to celebrate traditional crafts and engage the community. This event highlighted the brand's commitment to preserving and promoting traditional techniques.</p>
                        </div>
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={sundarisilks11} alt="" />
                    </div>
                    <div className="section__content">
                        <div className="title"><p className="project__detail--type">Branding materials</p></div>
                        <div className="text">
                            <p>Unique units were created for all physical branding materials, including shopping bags, thank you cards, and gift wrappers. The Marapachi dolls, the identity of the brand, were illustrated in eight different ways, each representing different types of sarees. Additionally, there were hoardings and banners showcasing the brand’s diversity and heritage.</p>
                            <p>The targeted marketing and high-profile events of the 25th anniversary campaign significantly boosted an awareness for Sundari Silks, with extensive media coverage in magazines, newspapers, and online platforms. This increased visibility led to a notable rise in sales, particularly for exclusive collections and during event periods, expanding the customer base with both new customers and repeat purchases. Additionally, the campaign strengthened relationships with existing customers, as evidenced by increased engagement and positive feedback. The social media following also grew, with higher participation in anniversary-related activities, further solidifying customer loyalty.</p>
                            <p>The 25th anniversary celebrations of Sundari Silks have not only commemorated its rich history but also set the stage for a promising future. By embracing both tradition and innovation, Sundari Silks continues to weave a legacy that honours its past while looking forward to new achievements. This case study reflects the company's journey, the challenges it overcame, and the strong community that has supported it through the decades.</p>
                        </div>
                    </div>
                </div>
            </section>
            <FooterCommonCTA
                content="Tell us your story. We’d love to hear from you."
                linkText="Let’s talk"
                linkUrl="/contact-us"
            />
        </div>
    );
}

export default SundariSilksWebsite;
