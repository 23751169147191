import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import ScrollToTop from './components/ScrollToTop'; // Import the ScrollToTop component
import Footer from "./components/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Service from "./pages/Service";
import Contact from "./pages/Contact";
import Work from "./pages/Work";
import Career from "./pages/Career";
import CalendarComponent from "./pages/CalendarComponent";
import Aha from './pages/work/aha';
import Amul from './pages/work/amul';
import AnnpoornaMasala from './pages/work/annapoorna-masala';
import ChenniSuperKings from './pages/work/chennai-super-kings';
import JoburgSuperKings from './pages/work/joburg-super-kings';
import Nova from './pages/work/nova';
import Nttf from './pages/work/nttf';
import RaceAyirathilOruKathai from './pages/work/race-ayirathil-oru-kathai';
import SundariSilksMargazhiUtsavam from './pages/work/sundari-silks-margazhi-utsavam';
import SundariSilksWebsite from './pages/work/sundari-silks-25-years';
import NotFound from "./pages/NotFound";

import "./index.scss";

function App() {
  return (
    <Router>
      <ScrollToTop /> {/* Place this component inside the Router to listen for route changes */}
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/madarth-about-us" element={<About />} />
          <Route path="/service" element={<Service />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/calendar" element={<CalendarComponent />} />
          <Route path="https://joinatmadarth.notion.site/" element={<Career />} />
          <Route path="/work" element={<Work />} />
          <Route path="/work/aha" element={<Aha />} />
          <Route path="/work/amul" element={<Amul />} />
          <Route path="/work/annpoorna-masala" element={<AnnpoornaMasala />} />
          <Route path="/work/chennai-super-kings" element={<ChenniSuperKings />} />
          <Route path="/work/joburg-super-kings" element={<JoburgSuperKings />} />
          <Route path="/work/nova" element={<Nova />} />
          <Route path="/work/nttf" element={<Nttf />} />
          <Route path="/work/race-ayirathil-oru-kathai" element={<RaceAyirathilOruKathai />} />
          <Route path="/work/sundari-silks-margazhi-utsavam" element={<SundariSilksMargazhiUtsavam />} />
          <Route path="/work/sundari-silks-25-years" element={<SundariSilksWebsite />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
