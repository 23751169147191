import React from "react";
import { Helmet } from "react-helmet-async";

import FooterCommonCTA from '../../components/FooterCommonCTA';


import novaPageCover from "../../images/work/nova/image/nova-cover.png";
// import nova1 from "../../images/work/nova/image/nova-1.png";
import nova2 from "../../images/work/nova/image/nova-2.png";
import nova3 from "../../images/work/nova/image/nova-3.png";
// import nova4 from "../../images/work/nova/image/nova-4.png";
// import nova5 from "../../images/work/nova/image/nova-5.png";
import nova6 from "../../images/work/nova/image/nova-6.png";
import nova7 from "../../images/work/nova/image/nova-7.png";
import nova8 from "../../images/work/nova/image/nova-8.png";
import nova9 from "../../images/work/nova/image/nova-9.png";


import novaVideoCover1 from "../../images/work/nova/video-cover/nova-1.png";
import novaVideoCover2 from "../../images/work/nova/video-cover/nova-2.png";
import novaVideoCover3 from "../../images/work/nova/video-cover/nova-3.png";


import novaVideo1 from "../../images/work/nova/video/nova-1.mp4";
import novaVideo2 from "../../images/work/nova/video/nova-2.mp4";
import novaVideo3 from "../../images/work/nova/video/nova-3.mp4";

function Nova() {
  return (
    <div>
      <Helmet>
        <title>
          NOVA
        </title>
        <meta
          name="description"
          content="Madarth is a branding, advertising, design and digital marketing agency. We create compelling and relevant solutions for our clients, across verticals."
        />
      </Helmet>

      <section className="project__detail">
            <div className="grid__box">
            <div className="section__title--content">
                        <div className="text">
                            <h1 className="project__detail--title">NOVA</h1>
                            <p className="project__detail--description">Launching Nova Sangamam with a Plus Lifestyle Strategy.</p>
                            <p className="project__detail--tag">
                                <span>Omnichannel campaign</span> | 
                                <span>Real estate</span>
                            </p>
                        </div>
                    </div>
                <div className="section__single">
                    <img loading="lazy" src={novaPageCover} alt="" />
                </div>
                {/* <div className="section__title--content">
                    <div className="title">
                        <h2 className="project__detail--year">2024</h2>
                    </div>
                    <div className="text">
                        <h1 className="project__detail--title">NOVA</h1>
                        <p className="project__detail--description">Launching Nova Sangamam with a Plus Lifestyle Strategy
                        </p>
                    </div>
                </div> */}
                <div className="section__content">
                    <div className="title">
                        <p className="project__detail--type">Omni channel campaign</p>
                    </div>
                    <div className="text">
                        <p>Nova Sangamam, a premium plotted development project in Manapakkam, was our latest launch for
                            Nova, a real estate brand based out of Tamil Nadu. Tasked with launching the project and
                            generating leads, we designed a campaign that capitalized on the project's unique value
                            proposition in a competitive market.</p>
                    </div>
                </div>

                <div className="section__single">
                    <video src={novaVideo1} poster={novaVideoCover1} autoPlay
                        loop muted preload="auto" width="640" height="360" playsInline className="width:100 height:auto">
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className="section__content">
                    <div className="text">
                        <p><strong>Campaign Strategy: Innime ellame Yes thaan. Plus thaan (Say yes to the plus
                                lifestyle)</strong></p>
                        <p>The core of our campaign was to creatively highlight the amenities and advantages that the
                            project offers. By focusing on the concept of a "Plus lifestyle," we emphasised the extra
                            value and amenities that come with each plot and villa. This slogan was consistently used
                            across all media to reinforce the message.</p>
                        <p>Our omni-channel campaign was a carefully orchestrated strategy that included impactful side
                            branding, a series of hoardings in strategic locations, bus branding, and ads placed in
                            leading newspapers to reach a broad audience. Additionally, we ran an extensive digital
                            campaign across social media platforms to engage potential buyers online. As the official
                            real estate partner of Chennai Super Kings (CSK), we integrated images of CSK players into
                            our promotional materials, leveraging their popularity to enhance the project's visibility
                            and appeal.</p>
                        <p>We introduced a dual-role character in our campaign to highlight the additional benefits.
                            This creative approach helped to communicate the unique selling points in an imapctful way.
                        </p>
                        <p>The campaign generated visibility for Nova Sangamam, leading to numerous leads and successful
                            sales. The omni-channel strategy ensured that we reached potential buyers through multiple
                            touchpoints, creating a presence in the market.</p>
                    </div>
                </div>
                <div className="section__single">
                    <img loading="lazy" src={nova2} alt="" />
                </div>
                <div className="section__single">
                    <img loading="lazy" src={nova3} alt="" />
                </div>

                <div className="section__single">
                    <video src={novaVideo2} poster={novaVideoCover2} autoPlay
                        loop muted preload="auto" width="640" height="360" playsInline className="width:100 height:auto">
                        Your browser does not support the video tag.
                    </video>
                </div>

                <div className="section__single">
                    <video src={novaVideo3} poster={novaVideoCover3} autoPlay
                        loop muted preload="auto" width="640" height="360" playsInline className="width:100 height:auto">
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className="section__single">
                    <img loading="lazy" src={nova6} alt="" />
                </div>

                <div className="section__single">
                    <img loading="lazy" src={nova7} alt="" />
                </div>
                <div className="section__single">
                    <img loading="lazy" src={nova8} alt="" />
                </div>
                <div className="section__single">
                    <img loading="lazy" src={nova9} alt="" />
                </div>
            </div>
        </section>
      <FooterCommonCTA
          content="Tell us your story. We’d love to hear from you."
          linkText="Let’s talk"
          linkUrl="/contact-us"
        />
    </div>
  );
}

export default Nova;
