import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import emailjs from 'emailjs-com';

const ContactSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  mobile: Yup.string()
    .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, 'Invalid mobile number')
    .required('Required'),
  message: Yup.string()
    .min(10, 'Too Short!')
    .required('Required'),
  type: Yup.string()
    .required('A selection is required'),
});

const ContactForm = () => {
  return (
    <div>
      <Formik
        initialValues={{ name: '', email: '', mobile: '', message: '', type: '' }}
        validationSchema={ContactSchema}
        onSubmit={(values, { setSubmitting }) => {
          const sendEmail = (values, setSubmitting) => {
            const templateParams = {
              from_name: values.name,
              reply_to: values.email,
              message: values.message,
              type: values.type,
              to_name: 'Admin',
              to_email: 'kavin@madarth.com', // Set recipient email
              from_email: 'webmaster@madarth.com', // Set sender email
              cc_email: 'pranitha@madarth.com', // Set CC email
            };

            emailjs.send(
              process.env.NEXT_PUBLIC_EMAILJS_SERVICE_ID,
              process.env.NEXT_PUBLIC_EMAILJS_TEMPLATE_ID,
              templateParams,
              process.env.NEXT_PUBLIC_EMAILJS_USER_ID
            )
              .then((response) => {
                console.log('Email successfully sent!', response.status, response.text);
                setSubmitting(false);
                alert('Email sent successfully!');
              }, (error) => {
                console.error('Failed to send email. Error: ', error);
                setSubmitting(false);
                alert('Failed to send email.');
              });
          };

          sendEmail(values, setSubmitting);
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <div className='form__group interested__service--lists'>
              <label className="form_label__title">Let’s begin today</label>
            </div>
            <div className='form__group'>
              <label className="form_label" htmlFor="name">Name:</label>
              <Field name="name" type="text" placeholder="Name" className={errors.name && touched.name ? "error-required" : "form__input"} />
              {errors.name && touched.name ? (<div className='error error__message'>{errors.name}</div>) : null}
            </div>
            <div className='form__group'>
              <label className="form_label" htmlFor="email">Email:</label>
              <Field name="email" type="email" placeholder="Email" className={errors.email && touched.email ? "error-required" : "form__input"} />
              {errors.email && touched.email ? <div className='error error__message'>{errors.email}</div> : null}
            </div>
            <div className='form__group'>
              <label className="form_label" htmlFor="mobile">Mobile:</label>
              <Field name="mobile" type="text" placeholder="Mobile" className={errors.mobile && touched.mobile ? "error-required" : "form__input"} />
              {errors.mobile && touched.mobile ? <div className='error error__message'>{errors.mobile}</div> : null}
            </div>
            <div className='form__group'>
              <label className="form_label" htmlFor="message">Message:</label>
              <Field as="textarea" name="message" placeholder="Message" className={errors.message && touched.message ? "error-required" : "form__input"} />
              {errors.message && touched.message ? <div className='error error__message'>{errors.message}</div> : null}
            </div>
            <div className='form__group'>
              <button type="submit" className='form__button'>
                <span className='button__text'>SEND MESSAGE</span>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M2 12C2 11.8011 2.07902 11.6103 2.21967 11.4696C2.36032 11.329 2.55109 11.25 2.75 11.25H20.4395L15.719 6.53097C15.5782 6.39014 15.4991 6.19913 15.4991 5.99997C15.4991 5.80081 15.5782 5.6098 15.719 5.46897C15.8598 5.32814 16.0508 5.24902 16.25 5.24902C16.4492 5.24902 16.6402 5.32814 16.781 5.46897L22.781 11.469C22.8508 11.5386 22.9063 11.6214 22.9441 11.7125C22.9819 11.8036 23.0013 11.9013 23.0013 12C23.0013 12.0986 22.9819 12.1963 22.9441 12.2874C22.9063 12.3785 22.8508 12.4613 22.781 12.531L16.781 18.531C16.6402 18.6718 16.4492 18.7509 16.25 18.7509C16.0508 18.7509 15.8598 18.6718 15.719 18.531C15.5782 18.3901 15.4991 18.1991 15.4991 18C15.4991 17.8008 15.5782 17.6098 15.719 17.469L20.4395 12.75H2.75C2.55109 12.75 2.36032 12.671 2.21967 12.5303C2.07902 12.3896 2 12.1989 2 12Z" fill="white"/></svg>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ContactForm;
