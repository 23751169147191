import React from 'react';
import './Accordion.scss';

const Accordion = ({ title, children, isOpen, onClick }) => {
    return (
        <div className="accordion">
            <div className={isOpen ? 'accordion-title accordion--visible' : 'accordion-title accordion--hidden'} onClick={onClick}>
                {title}
                <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"><path fill="#fff" fillRule="evenodd" d="M12.143 20a.571.571 0 0 0 .572-.571V5.952l3.595 3.596a.572.572 0 0 0 .809-.809l-4.571-4.571a.571.571 0 0 0-.81 0l-4.57 4.571a.572.572 0 0 0 .809.81l3.595-3.597v13.477a.571.571 0 0 0 .571.571Z" clipRule="evenodd"/></svg></span>
            </div>
           <div className="accordion-content">{children}</div>
        </div>
    );
};

export default Accordion;
