// src/pages/NotFound.js

import React from 'react';

function NotFound() {
  return (
    <div style={{ textAlign: 'center', padding:'20px', marginTop: '50px', marginBottom : '50px' }}>
      <h1>404</h1>
      <p>Page Not Found</p>
      <p>The page you are looking for does not exist or another error occurred.</p>
      <p>Go back, or head over to the homepage to choose a new direction.</p>
    </div>
  );
}

export default NotFound;
