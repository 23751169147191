import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import FooterCommonCTA from "../components/FooterCommonCTA";

// import serviceFuture from "../images/page-service/madarth-service-future-proofing-family-enterprises-t.png";
// import serviceGrowth from "../images/page-service/madarth-service-guiding-growth-t.png";
// import serviceInformation from "../images/page-service/madarth-service-indian-information-guidance-t.png";

import "./service.scss";

function Service() {
  return (
    <div>
      <Helmet>
        <title>Service | MADARTH® </title>
        <meta
          name="description"
          content="Traditional wisdom meets Digital edge. We solve business challenges and build brands that thrive."
        />
      </Helmet>
      <div className="page--Service">
        <section className="grid__box pageHero ">
          <h1 className="pageHero__title">
            Traditional <br /> wisdom meets <br /> digital edge
          </h1>
          <p className="pageHero__content">
            We don't claim to understand India’s landscape better than anyone.
            But we do have an original way of bringing it to life. With deep
            roots in traditional advertising and a sharp digital edge, we solve
            business challenges and build brands that thrive.
          </p>
        </section>
        <section className="grid__box service--lists">
          <div className="service--list">
            <div className="service--list__title">
              <span className="service--list__title__no">1</span>
              <span className="service--list__title__text">
                Future-proofing family <br /> enterprises
              </span>
            </div>
            <div className="service--list__content">
              <div className="service--list__content--image">
                {/* <img
                  src={serviceFuture}
                  alt="Future-proofing family enterprises"
                /> */}
              </div>
              <div className="service--list__content--content">
                <h2 className="heading--secondary">
                  Maybe the next generation isn’t keen on stepping in, or
                  perhaps embracing digital for your business feels like a leap.
                  Whatever the challenge, we’re here to listen and guide you
                  forward while preserving the essence of your brand.
                </h2>
                <ul>
                  <li>Decoding the foundation of the business</li>
                  <li>Succession and mentoring</li>
                  <li>
                    Growth planning across new verticals, horizontals and
                    business models
                  </li>
                  <li>
                    Digital transformation for brand building and performance
                  </li>
                  <li>
                    Sustenance in scale through crafting a compelling global
                    brand
                  </li>
                </ul>
                <div className="service--list__content--cta">
                  <Link to="/contact-us" className="button button__text">
                    Let’s talk
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#000"
                        fillRule="evenodd"
                        d="M1.5 11.249a.666.666 0 0 1 .667-.667h15.721l-4.195-4.194a.667.667 0 1 1 .944-.944l5.332 5.333a.666.666 0 0 1 0 .944l-5.332 5.332a.667.667 0 1 1-.944-.943l4.195-4.194H2.167a.667.667 0 0 1-.667-.667Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="service--list">
            <div className="service--list__title">
              <span className="service--list__title__no">2</span>
              <span className="service--list__title__text">
                Guiding growth in a dynamic <br /> world
              </span>
            </div>
            <div className="service--list__content">
              <div className="service--list__content--image">
                {/* <img
                  src={serviceGrowth}
                  alt="Guiding growth in a dynamic world"
                /> */}
              </div>
              <div className="service--list__content--content">
                <h2 className="heading--secondary">
                  We help you expand into new markets and offer guidance on
                  strategic acquisitions. As business environments change, we’ll
                  work with you to refresh your brand, ensuring it remains
                  relevant. Together, we’ll position your brand for steady,
                  sustainable growth. Let’s grow your brand.
                </h2>
                <ul>
                  <li>New market entry strategy</li>
                  <li>M&A Brand strategy</li>
                  <li>Digital transformation</li>
                </ul>
                <div className="service--list__content--cta">
                  <Link to="/contact-us" className="button button__text">
                    Let's grow your brand
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#000"
                        fillRule="evenodd"
                        d="M1.5 11.249a.666.666 0 0 1 .667-.667h15.721l-4.195-4.194a.667.667 0 1 1 .944-.944l5.332 5.333a.666.666 0 0 1 0 .944l-5.332 5.332a.667.667 0 1 1-.944-.943l4.195-4.194H2.167a.667.667 0 0 1-.667-.667Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="service--list">
            <div className="service--list__title">
              <span className="service--list__title__no">3</span>
              <span className="service--list__title__text">
                India entry with informed <br /> guidance
              </span>
            </div>
            <div className="service--list__content">
              <div className="service--list__content--image">
                {/* <img
                  src={serviceInformation}
                  alt="India entry with informed guidance"
                /> */}
              </div>
              <div className="service--list__content--content">
                <h2 className="heading--secondary">
                  We help foreign brands to explore India's rich diversity,
                  developing tailored strategies. Through alpha and beta
                  testing, we ensure your brand resonates locally for a
                  confident and successful market launch.
                </h2>
                <ul>
                  <li>Positioning and channel strategy</li>
                  <li>Brand stewardship, communication and engagement</li>
                </ul>
                <div className="service--list__content--cta">
                  <Link to="/contact-us" className="button button__text">
                  Let’s Launch
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#000"
                        fillRule="evenodd"
                        d="M1.5 11.249a.666.666 0 0 1 .667-.667h15.721l-4.195-4.194a.667.667 0 1 1 .944-.944l5.332 5.333a.666.666 0 0 1 0 .944l-5.332 5.332a.667.667 0 1 1-.944-.943l4.195-4.194H2.167a.667.667 0 0 1-.667-.667Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="grid__box service--capabilities">
          <h2 className="heading">Our Capabilities</h2>
          <p>All you need to stay relevant in a digital, data-driven world.</p>
          <div className="service--capabilities__lists">
            <div className="service--capabilities__list">
              <h3 className="service--capabilities__list__title">Branding</h3>
              <h4>Brand Strategy & Consultation</h4>
              <ul>
                <li>Brand Positioning</li>
                <li>Brand Architecture</li>
                <li>Competitor & Market Analysis</li>
                <li>Brand Purpose, Vision & Mission</li>
              </ul>
              <h4>Brand Identity Design</h4>
              <ul>
                <li>Logo Design & Development</li>
                <li>Brand Naming & Tagline Creation</li>
                <li>Visual Identity Systems</li>
                <li>Typography & Colour Palette Creation</li>
              </ul>
              <h4>Brand Guidelines</h4>
              <ul>
                <li>Comprehensive Brand Manuals</li>
                <li>Voice & Tone Guidelines</li>
                <li>Visual and Communication Style Guide</li>
                <li>Consistency in Multi-channel Branding</li>
              </ul>
              <h4>Rebranding</h4>
              <ul>
                <li>Brand Refresh & Evolution</li>
                <li>Market Repositioning</li>
                <li>Legacy Brand Modernisation</li>
                <li>Brand Equity Audit</li>
              </ul>
              <h4>Packaging Design</h4>
              <ul>
                <li>Product Packaging Concepts</li>
                <li>Eco-friendly Packaging Solutions</li>
                <li>Label Design</li>
                <li>Structural and Functional Packaging</li>
              </ul>
            </div>
            <div className="service--capabilities__list">
              <h3 className="service--capabilities__list__title">
                Communication
              </h3>
              <h4>Advertising Campaigns</h4>
              <ul>
                <li>Print, TV, Radio, and Outdoor Ads</li>
                <li>
                  Digital Ad Campaigns (Google, Facebook,  <br />  Instagram, LinkedIn,
                  YouTube)
                </li>
                <li>Creative Planning & Copywriting</li>
              </ul>
              <h4>Content Strategy & Studio</h4>
              <ul>
                <li>Content Writing & Editorial Services</li>
                <li>Scriptwriting & Video Production</li>
                <li>Blog & Article Writing</li>
                <li>Social Media Content Strategy & Development</li>
              </ul>
              <h4>Digital Media Outreach</h4>
              <ul>
                <li>Press Release Writing</li>
                <li>Influencer & Celebrity Collaborations</li>
                <li>
                  Crisis Communication & Reputation Management (ORM - Online
                  Reputation Management)
                </li>
              </ul>
              <h4>Digital Marketing</h4>
              <ul>
                <li>Search Engine Optimization (SEO)</li>
                <li>Search Engine Marketing (SEM)</li>
                <li>Social Media Marketing (SMM)</li>
                <li>Email Marketing Campaigns</li>
              </ul>
              <h4>Internal Communication</h4>
              <ul>
                <li>Corporate Communication Strategy</li>
                <li>Employee Engagement Campaigns</li>
                <li>Internal Newsletters</li>
                <li>Leadership Messaging</li>
              </ul>
            </div>
            <div className="service--capabilities__list">
              <h3 className="service--capabilities__list__title">
                Experiences
              </h3>
              <h4>Website Design & Development</h4>
              <ul>
                <li>UX/UI Design</li>
                <li>Custom Website Development</li>
                <li>E-commerce Solutions</li>
                <li>Website Maintenance & Optimisation</li>
              </ul>
              <h4>Digital Transformation</h4>
              <ul>
                <li>Digital Experience Strategy</li>
                <li>Omni-channel Customer Journeys</li>
                <li>Martech Consultation (CRM, Automation Tools)</li>
                <li>Data Analytics & Consumer Insights</li>
              </ul>
              <h4>Event Ideation & Design</h4>
              <ul>
                <li>Corporate Events & Launch Ideas</li>
                <li>Experiential Marketing Campaigns</li>
                <li>Brand Activations Ideas & Execution Support</li>
                <li>Virtual Events & Webinars</li>
              </ul>
              <h4>Retail Experience Design</h4>
              <ul>
                <li>In-store Branding & Visual Merchandising</li>
                <li>Multinational Corporate Office Branding</li>
                <li>Franchise Store Layout Design</li>
                <li>Pop-up Stores & Interactive Installations</li>
                <li>
                  Augmented Reality (AR) & Virtual Reality (VR) Experiences
                </li>
              </ul>
              <h4>Customer Engagement & Loyalty Programs</h4>
              <ul>
                <li>Customer Relationship Management (CRM) Support</li>
                <li>Loyalty Program Ideation & Development</li>
                <li>Consumer Surveys & Feedback Loops</li>
                <li>Personalised Experience Design</li>
              </ul>
            </div>
          </div>
        </section>
        <FooterCommonCTA
          content="Perhaps a chat over a chai?"
          linkText="We are here"
          linkUrl="/contact-us"
        />
      </div>
    </div>
  );
}

export default Service;
